const REACT_APP_API_URL = process.env.REACT_APP_API_URL; 

class SubscriberPortfolioService {

    async getSubscriberPortfolioData(initData, retries) {

        try {
            
            retries = typeof retries === 'number'? retries : 0;
    
            if ( retries > 3 ) {
    
                alert('Sorry, could not fetch your portfolio data.\nPlease try again later');
                window.close();
    
            }
            
            const response = await fetch(REACT_APP_API_URL+'/subscriber', {
                method: 'POST',
                body: initData,
                headers: {
                    'Content-type': 'text/plain'
                }
            })
    
            if ( response.status == 200 ) {
    
                return await response.json();
    
            } else {
    
                console.error('Error to get subscriber portfolio: ', await response.text(), '\nStatus code: ', response.status);
    
                if ( response.status == 401 ) {
    
                    alert('Sorry, you are not a Wizard!');
                    window.close();
    
                }

                retries += 1;
                
                return await this.getSubscriberPortfolioData(initData, retries);
    
            }

        } catch (error) {

            alert('Error fetching your portfolio data: '+error+'\nPlease try again later.');
            window.close();
            
        }

    }

}

module.exports.SubscriberPortfolioService = new SubscriberPortfolioService();