import './SnapshotViewerPage.css';
import { useEffect, useState } from 'react';

function SnapshotViewerPage({
    userData
}) {

    const [snapshotColumns, setSnapshotColumns] = useState(null);
    const [snapshotLines, setSnapshotLines] = useState(null);
    const [filteredSnapshotLines, setFilteredSnapshotLines] = useState(null);
    const [supply, setSupply] = useState(null);
    const [idxOfItemsQty, setIdxOfItemsQty] = useState(null);

    useEffect(() => {



    }, []);

    useEffect(() => {

    }, [userData]);

    const handleSnapshotInput = (e) => {
        const file = e.target.files[0];
        if (file) {
            var reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = function (evt) {
                const lines = evt.target.result.replaceAll(`\r`).split(`\n`);
                const columns = lines[0].replaceAll('"', '').split(',');
                setSnapshotColumns(columns);
                const idxOfItemsQtyRef = columns.indexOf('items_count');
                setIdxOfItemsQty(idxOfItemsQtyRef);
                const valuesArray = [];
                let totalSupply = 0;
                for (let index = 1; index < lines.length; index++) {
                    const line = lines[index];
                    const values = line.replaceAll(`"`, ``).split(',');
                    valuesArray.push(values);
                    totalSupply += parseInt(values[idxOfItemsQtyRef]);
                }
                console.log('lines: ', columns);
                console.log('lines len: ', lines.length);
                valuesArray.sort((a, b) => {
                    return parseInt(b[idxOfItemsQtyRef]) - parseInt(a[idxOfItemsQtyRef])
                })
                setSupply(totalSupply);
                setSnapshotLines(valuesArray);
            }
            reader.onerror = function (evt) {
                alert('Error to process selected CSV. Try again with another file.');
            }
        }

    }

    useEffect(() => {

        console.log('snapshotLines: ', snapshotLines);
        console.log('totalSupply: ', supply);

    }, [snapshotLines, supply]);

    const goToPortfolioTracker = () => {
        const url = window.location.href.split('?')[0].split('/')[0];
        window.location.search = new URLSearchParams();
        window.location.href = url + '?';
    }

    const handleAddressSearch = (e) => {
        const query = e.target.value;
        if (query == null || query == `` || query.length < 4) {
            if (Array.isArray(filteredSnapshotLines)) {
                setFilteredSnapshotLines(null);
            }
        } else {
            const filtered = snapshotLines.filter((line) => {
                if (line.some(val => val.toString().indexOf(query) > -1)) {
                    return line;
                }
            })
            setFilteredSnapshotLines(filtered);
        }
    }

    return (
        <div className="Snapshot-App">
            <header className="Snapshot-header disable-select">
                <div className='row'>
                    <div style={{ width: `33%`, marginRight: '1em', float: `left`, alignContent: `start` }}>
                        <p onClick={() => { goToPortfolioTracker() }} className='glowingMoon limit-text snapshot-viewer-button' >Go back 🔙</p>
                    </div>
                    <div style={{ width: `33%` }} className='snap-welcomeAnimation'>
                        {userData && userData != null ?
                            <p className='glowingMoon' >
                                🧙‍♂😺🪄✨🌕<br />
                                Hello {userData.user.first_name + ' ' + userData.user.last_name}!
                            </p>
                            :
                            <></>
                        }
                    </div>
                    <div style={{ width: `33%` }}>
                    </div>
                </div>
            </header>
            <div className='body'>
                {Array.isArray(snapshotLines) && snapshotLines.length > 0 ?
                    <>
                        <div className='portfolioResume' style={{ width: '100%' }} >
                            <p className='glowingMoon limit-text' >Collection symbol: {snapshotLines[0][0]}</p>
                            <p className='glowingMoon limit-text' >Supply: {supply}</p>
                            <p className='glowingMoon limit-text' >{snapshotLines.length} unique addresses!</p>
                            <input onChange={(e) => handleAddressSearch(e)} type='text' className='search-input limit-text' placeholder='Search address' />
                        </div>
                        <div className='listContainer'>
                            <div className='listHeader row'>
                                {Array.isArray(snapshotColumns) ?
                                    snapshotColumns.map((column, columnIdx) => {
                                        if (columnIdx != 0) {
                                            return (
                                                <div className='listHeaderItem'>
                                                    {column}
                                                </div>
                                            );
                                        }
                                    })
                                    :
                                    <></>
                                }
                            </div>
                            {Array.isArray(snapshotLines) ?
                                [Array.isArray(filteredSnapshotLines)? filteredSnapshotLines : snapshotLines][0].map((line, lineIdx) => {
                                    return (<div className='row'>
                                        {line.map((val, valIdx) => {
                                            if (valIdx != 0) {

                                                return (
                                                    <div className='listHeaderItem'>
                                                        <pre>
                                                            {valIdx == idxOfItemsQty ? (`${val}/${supply}  (` + (parseFloat(val * 100 / supply).toFixed(2)) + `%)`) : val}
                                                        </pre>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>)
                                })
                                :
                                <></>
                            }
                        </div>
                    </>
                    :
                    <div id='fileSelectContainer'>
                        <label id="fileSelectLabel" for="fileSelect">Import snapshot</label>
                        <input onChange={(e) => handleSnapshotInput(e)} id="fileSelect" type="file" accept=".csv, .CSV, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/plain" />
                    </div>
                }
            </div>
        </div>
    );
}

export default SnapshotViewerPage;
