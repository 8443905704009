import './LoadingHomePage.css';
import { useEffect } from 'react';

function LoadingHomePage({
    userData
}) {

    useEffect(() => {

    }, []);

    useEffect(() => {

    }, [userData]);

    return (
        <div className="App">
            <header className="App-header disable-select">
                <div className='welcomeAnimation'>
                    <p className='glowingMoon' style={ { transform: 'scale(.5)' } } >
                        Loading, please wait . . .
                    </p>
                    {userData && userData != null ?
                        <p className='glowingMoon' >
                            🧙‍♂😺🪄✨🌕<br />
                            Hello {userData.user.first_name + ' ' + userData.user.last_name}!
                        </p>
                        :
                        <></>
                    }
                </div>
            </header>
        </div>
    );
}

export default LoadingHomePage;
